<template>
  <div class="">
    <div class="text-subhdr-3 text-red-700 mb-7" v-html="text_remove_account"></div>
    <form action="/" method="get" autocomplete="false">
      <div class="mb-4">
        <label class="text-body-1 pb-3" for="remove_confirm">
          bevestigen
        </label>
        <input class="input-main text-body-2" id="remove_confirm" ref="remove_confirm" v-model="remove_confirm" type="text" placeholder="DELETE" autocomplete="false">
      </div>
      <div class="mb-7">
        <label class="text-body-1 pb-3" for="password_remove">
          je wachtwoord
        </label>
        <input class="input-main text-body-2" id="password_remove" ref="password_remove" v-model="password_remove" type="password" autocomplete="false">
      </div>
    </form>
    <div class="p-4 mb-7 rounded-lg bg-orange-50 border border-orange-600 text-orange-600 font-mono font-bold flex flex-row" v-show="error_show">
      <div class="text-orange-400">
        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
      </div>
      <div class="ml-4 animate-pulse">
        {{error_message}}
      </div>
    </div>
    <btn_default text="verwijderen" type="w100" @btn-click="formHandle" :state="btn_state" />


  </div>
</template>

<script>
import globals from "@/modules/globals"
import { EventBus } from "@/modules/event_bus"
import api from "@/modules/api"
import control_main from '@/modules/control_main'

import btn_default from '@/components/ui/btn_default.vue'

var lv = {}
lv.thisVue = null
lv.formData = {}

export default {
  name: 'account_remove',
  components: {
    btn_default,
  },
  props: {
  },
  data () {
    return {
      text_remove_account: '<br/>Weet je echt heel zeker dat je je account wilt verwijderen?<br/>Dit kan niet ongedaan gemaakt worden.<br/><br/>Schrijf "DELETE" om te bevestigen.',
      text_remove_account_confirm: 'Schrijf "DELETE" om te bevestigen.',
      text_remove_account_alert: '',
      remove_confirm: null,
      password_remove: null,
      btn_state: null,
      error_show: false,
      error_message: null
    }
  },
  mounted () {
    lv.thisVue = this
  },
  methods: {
    formHandle() {
      if(this.remove_confirm != 'DELETE'){
        lv.thisVue.error_message = 'Bevestig met DELETE'
        lv.thisVue.error_show = true
        return
      }
      console.log('will remove your account')
      lv.formData = {
        user_id: globals.user_data.id,
        first_name: globals.user_data.first_name,
        password: this.password_remove
      }
      formSendRemoveAccount(lv.formData)
    }
  }
}

function reset_(){
  if(lv.thisVue){
    lv.thisVue.remove_confirm = null
    lv.thisVue.password_remove = null
    lv.thisVue.error_show = false
    lv.thisVue.btn_state = null
  }
}

EventBus.$on('modal_closed', (e) => {
   reset_()
})

/* ---------------------------------------------------------------------------- */

lv.dataTask_remove_account = "remove_account"
lv.dataEvent_remove_account = "data_remove_account"
lv.account_is_removed = false

function formSendRemoveAccount(form_data) {
  lv.thisVue.btn_state = 'processing'
  lv.thisVue.error_show = false
  api.call({
    task: lv.dataTask_remove_account,
    event: lv.dataEvent_remove_account,
    global: false,
    formData: form_data
  })
}
function dataLoadedRemoveAccount(d) {
  lv.thisVue.btn_state = null
  console.log(d)

  if(d.data.respond == 'password_incorrect'){
    lv.thisVue.error_message = 'Wachtwoord onjuist'
    lv.thisVue.error_show = true
    return
  }
  if(d.data.respond == 'remove_account_done'){
    console.log('RemoveAccount done!!!!!!!!!!!')
    globals.account_is_removed = true
    control_main.log_out()
    return
  }
  lv.thisVue.error_message = 'Er is helaas iets misgegaan'
  lv.thisVue.error_show = true
}

EventBus.$on(lv.dataEvent_remove_account, (e) => {
  dataLoadedRemoveAccount(e);
})

EventBus.$on('logged_out', (e) => {
  if(globals.account_is_removed){
    lv.thisVue.$router.push({ name: 'AccountRemoved' }).catch(()=>{})
  }
})

/* ---------------------------------------------------------------------------- */

</script>
<style scoped>

</style>