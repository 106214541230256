<template>
  <div class="">
    
    <div class="mb-4">
      <label class="text-body-1 pb-3" for="password">
        huidig wachtwoord
      </label>
      <input class="input-main text-body-2" id="password" ref="password" v-model="password" type="password">
    </div>
    <div class="mb-4">
      <label class="text-body-1 pb-3" for="password_new">
        nieuw wachtwoord
      </label>
      <input class="input-main text-body-2" id="password_new" ref="password_new" v-model="password_new" type="password">
    </div>
    <div class="mb-7">
      <label class="text-body-1 pb-3" for="password_new_again">
        nieuw wachtwoord nogmaals
      </label>
      <input class="input-main text-body-2" id="password_new_again" ref="password_new_again" v-model="password_new_again" type="password">
    </div>
    <div class="p-4 mb-7 rounded-lg bg-orange-50 border border-orange-600 text-orange-600 font-mono font-bold flex flex-row" v-show="error_show_password">
      <div class="text-orange-400">
        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
      </div>
      <div class="ml-4 animate-pulse">
        {{error_message_password}}
      </div>
    </div>
    <btn_default text="wijzig" type="w100" @btn-click="formHandle" :state="btn_state" />


  </div>
</template>

<script>
import globals from "@/modules/globals"
import { EventBus } from "@/modules/event_bus"
import api from "@/modules/api"
import control_main from '@/modules/control_main'

import btn_default from '@/components/ui/btn_default.vue'

var lv = {}
lv.thisVue = null
lv.formData = {}

export default {
  name: 'password_change',
  components: {
    btn_default,
  },
  props: {
  },
  data () {
    return {
      password: null,
      password_new: null,
      password_new_again: null,
      error_show_password: false,
      error_message_password: null,
      btn_state: null
    }
  },
  mounted () {
    lv.thisVue = this
  },
  methods: {
    formHandle() {
      lv.formData = {
        password: this.password,
        password_new: this.password_new,
        password_new_again: this.password_new_again
      }
      //console.log(lv.formData)
      formSendPasswords(lv.formData)
    }
  }
}

function reset_(){
  if(lv.thisVue){
    lv.thisVue.password = null
    lv.thisVue.password_new = null
    lv.thisVue.password_new_again = null
    lv.thisVue.error_show_password = false
    lv.thisVue.btn_state = null
  }
}

EventBus.$on('modal_closed', (e) => {
   reset_()
})

/* ---------------------------------------------------------------------------- */

lv.dataTask_passwords = "profile_set_new_password"
lv.dataEvent_passwords = "data_profile_set_new_password"

function formSendPasswords(form_data) {
  lv.thisVue.error_show_password = false
  form_data.user_id = globals.user_data.id
  lv.thisVue.btn_state = 'processing'
  api.call({
    task: lv.dataTask_passwords,
    event: lv.dataEvent_passwords,
    global: false,
    formData: form_data
  })
}
function dataLoadedPasswords(d) {
  lv.thisVue.btn_state = null
  if (d.result == "nok") {
    formErrorPasswords(d.data.respond);
    return
  }
  if(d.data.respond == 'profile_set_new_password done'){
    globals.login_after_new_password = true
    lv.msg = "Wachtwoord bijgewerkt."
    //console.log('passwords done!!!!!!!!!!!')
    control_main.log_out()
    return
  }
  lv.thisVue.error_message_password = 'Er is helaas iets misgegaan. '
  lv.thisVue.error_message_password += d.data.respond
  lv.thisVue.error_show_password = true
}
EventBus.$on(lv.dataEvent_passwords, (e) => {
  dataLoadedPasswords(e);
})

function formErrorPasswords(e) {
  console.log(e);
  lv.thisVue.error_message_password = lv.formIssuesPasswords[e].message
  lv.thisVue.error_show_password = true
}

lv.formIssuesPasswords = {
  password_empty: {
    field: "password",
    message: "Wachtwoord is vereist",
  },
  password_incorrect: {
    field: "password",
    message: "Huidig wachtwoord onjuist",
  },
  password_new_empty: {
    field: "password_new",
    message: "Wachtwoord is vereist",
  },
  password_new_again_empty: {
    field: "password_new_again",
    message: "Wachtwoord is vereist",
  },
  password_nomatch: {
    field: "password_new_again",
    message: "Wachtwoorden komen niet overeen",
  },
  password_strength: {
    field: 'password',
    message: 'Wachtwoord is niet sterk genoeg.\n\rGebruik tenminste 8 karakters met minimaal 1 hoofdletter, 1 kleine letter en 1 getal.'
  }
}

/* ---------------------------------------------------------------------------- */

</script>
<style scoped>

</style>