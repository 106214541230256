<template>

  <div class="inline-block">
    <button
      class="bg-white text-black hover:bg-black hover:text-white py-2 px-2 rounded-full focus:outline-none focus:bg-black transition duration-300 ease-in-out"
       @click.prevent="$emit('btn-click')"
    >
      <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </button>
  </div>


</template>


<script>


export default {
  name: 'btn_icon',
  props: ['type','icon'],
  data() {
    return {
    }
  },
  mounted() {
  },
  watch: { 
  }
}
</script>

<style lang="scss" scoped>

</style>
