<template>

  <div class="fixed w-full h-full top-0 left-0 z-50 hidden opacity-0" id="modal_default"> 
    <div class="pos-g bg-black opacity-90"></div>
    <div class="pos-g bg-pattern-1 opacity-10"></div>
      <div class="w-full h-full overflow-y-scroll relative">
        <div class=" relative flex items-center justify-center min-h-full w-full p-7">
          <div class="pos-g" @click="hide"></div>
          <div class="p-4">        
            <div class="rounded-lg bg-white p-8 w100 shadow-custom relative" id="modal_content" v-bind:class="size">
              <div class="text-hdr-2 break-words pb-6 pr-10" id="modal_title">
                {{modal_title}}
              </div>
              <div id="modal_body">
                <div class="text-subhdr-3 pb-4 wysiwyg_text break-all mxmd:mt-8" v-html="modal_body" v-if="modal_body_text"></div>
                <password_change v-if="module_password_change" />
                <account_remove v-if="module_account_remove" />
              </div>
              <div class="absolute right-0 top-0 p-5">
                <btn_icon icon="close" @btn-click="hide" id="modal_btn" />
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import gsap from 'gsap'

import btn_icon from '@/components/ui/btn_icon.vue'
import password_change from '@/components/account/password_change.vue'
import account_remove from '@/components/account/account_remove.vue'

var lv = {}
lv.thisVue = null
lv.body = document.getElementsByTagName('BODY')[0]

lv.modal_data_set = {}

export default {
  name: 'modal_default',
  components: {
    btn_icon,
    password_change,
    account_remove
  },
  props: [],
  data() {
    return {
      size: 'maxwlg',
      modal_title: null,
      modal_body: null,
      modal_body_text: true,
      module_password_change: false,
      module_account_remove: false
    }
  },
  mounted() {

    lv.thisVue = this
    lv.thisVue.pages = {}

    console.log('modal_default mounted')
    

    lv.dataTask = "textpages_fetch"
    lv.dataEvent = "data_textpages_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e)
    })

    lv.pages = ['gebruiksvoorwaarden','privacyverklaring']
    lv.thisVue.pages = {}

    function dataLoaded(d) {
    console.log('modal_default dataLoaded')

      lv.nr = 0
      while(lv.nr < lv.pages.length){
        lv.modal_data_set[lv.pages[lv.nr]] = {}
        lv.modal_data_set[lv.pages[lv.nr]].title = globals.api_data[lv.dataTask].items[lv.pages[lv.nr]].title
        lv.modal_data_set[lv.pages[lv.nr]].content = globals.api_data[lv.dataTask].items[lv.pages[lv.nr]].text
        lv.nr++
      }
    }

  },
  methods:{
    hide(){
      modal_hide()
    }
  },
  destroyed(){
    gsap.delayedCall(0.5, switchOverflowOut)
  },
}

EventBus.$on('modal_open', (e) => {
   modal_populate(e)
   modal_show()
})

function modal_mode_reset(){
  lv.thisVue.size = 'maxwlg'
  lv.thisVue.modal_body_text = true
  lv.thisVue.module_password_change = false
  lv.thisVue.module_account_remove = false
}

function modal_populate(e){
  modal_mode_reset()
  lv.thisVue.modal_title = lv.modal_data_set[e.id].title
  gsap.set('#modal_title',{display:'block'})
  if (e.size) {
    if (e.size == 'small') {
      lv.thisVue.size = 'maxwsm'
    }
  }
  if(e.module){
    lv.thisVue.modal_body_text = false
    lv.thisVue['module_' + e.module] = true
    return
  }
  lv.thisVue.modal_body = lv.modal_data_set[e.id].content
  lv.thisVue.modal_body = globals.api_data.textpages_fetch.items[e.id].text
  gsap.set('#modal_title',{display:'none'})
}

function modal_show(){
  lv.tl = gsap.timeline()
  lv.tl.call(switchOverflowIn)
  lv.targ = '#modal_default'
  lv.tl.set(lv.targ,{display: 'block'})
  lv.targ = '#modal_content'
  lv.tl.set(lv.targ,{opacity: 0, scale:0.95})
  lv.targ = '#modal_title'
  lv.tl.set(lv.targ,{opacity: 0, y: 10})
  lv.targ = '#modal_body'
  lv.tl.set(lv.targ,{opacity: 0, y: 5})
  lv.targ = '#modal_btn'
  lv.tl.set(lv.targ,{opacity: 0, scale: 0.1})

  lv.targ = '#modal_default'
  lv.tl.to(lv.targ, {duration: 2, opacity:1, ease:'power3.inOut'})
  lv.targ = '#modal_content'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, scale: 1, ease:'power3.inOut'},'-=1')
  lv.targ = '#modal_title'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, y: 0, ease:'power3.inOut'} ,'-=0.5')
  lv.targ = '#modal_body'
  lv.tl.to(lv.targ, {duration: 1, opacity:1, y: 0, ease:'power3.inOut'} ,'-=0.5')
  lv.targ = '#modal_btn'
  lv.tl.to(lv.targ, {duration: 3, opacity:1, scale: 1, ease:'elastic.out'} ,'-=0.5')
  lv.tl.timeScale(3)

}

function modal_hide(){
  lv.tl = gsap.timeline()
  lv.targ = '#modal_btn'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, ease:'expo.inOut'})
  lv.targ = '#modal_title'
  lv.tl.to(lv.targ, {duration: 1, opacity: 0, ease:'power3.inOut'} ,'-=0.7')
  lv.targ = '#modal_body'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, ease:'power3.inOut'} ,'-=0.7')
  lv.targ = '#modal_content'
  lv.tl.to(lv.targ, {duration: 1, opacity:0, scale: 0.95, ease:'power3.inOut'},'-=0.5')
  lv.targ = '#modal_default'
  lv.tl.to(lv.targ, {duration: 2, opacity:0, display: 'none', ease:'power3.inOut'},'-=0.5')
  lv.tl.call(switchOverflowOut)
  lv.tl.call(modal_closed)
  lv.tl.timeScale(3)
}

function switchOverflowIn(){
  lv.body_width_offset = getScrollbarCompensation()
  lv.body.style.overflowY = 'hidden'
  lv.body.style.paddingRight = lv.body_width_offset + 'px'
}
function switchOverflowOut(){
  lv.body.style.overflowY = 'scroll'
  lv.body.style.paddingRight = 0
}

function getScrollbarCompensation() {
  lv.start_width = lv.body.offsetWidth
  lv.body.style.overflowY = 'hidden'
  lv.end_width = lv.body.offsetWidth
  lv.body.style.overflowY = 'scroll'
  return lv.end_width - lv.start_width
}

function modal_closed(){
  EventBus.$emit('modal_closed')
}

lv.modal_data_set = {
  'gebruiksvoorwaarden':{
    title: 'Algemene voorwaarden',
    content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br/>Nulla nec enim libero. Phasellus vitae tincidunt arcu, sed mollis mauris. Donec gravida ante et dignissim bibendum.<br/>Vivamus viverra ante aliquam ultricies posuere. Nulla metus diam, aliquet eget sapien sit amet, tempus tincidunt lectus. Vivamus et imperdiet libero. Pellentesque molestie ut urna ac sagittis.<br/>Aenean quis libero egestas urna posuere rhoncus eget a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur euismod massa quis felis interdum viverra.<br/>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce convallis consectetur odio eget auctor.'
  },
  'privacyverklaring':{
    title: 'Privacy policy',
    content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br/>Nulla nec enim libero. Phasellus vitae tincidunt arcu, sed mollis mauris. Donec gravida ante et dignissim bibendum.<br/>Vivamus viverra ante aliquam ultricies posuere. Nulla metus diam, aliquet eget sapien sit amet, tempus tincidunt lectus. Vivamus et imperdiet libero. Pellentesque molestie ut urna ac sagittis.<br/>Aenean quis libero egestas urna posuere rhoncus eget a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur euismod massa quis felis interdum viverra.<br/>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce convallis consectetur odio eget auctor.'
  },
  'wachtwoord-wijzigen':{
    title: 'Wachtwoord wijzigen',
    content:'[[module]]'
  },
  'account-verwijderen':{
    title: 'Account verwijderen',
    content:'[[module]]'
  },
}

EventBus.$on('logged_out', (e) => {
  switchOverflowOut()
})

</script>

<style lang="scss" scoped>

.maxwlg{
  max-width: 1040px;
}
.maxwsm{
  max-width: 580px;
}
.shadow-custom{
  box-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.bg-pattern-1{
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%232f2f2f' fill-opacity='0.5' fill-rule='evenodd'/%3E%3C/svg%3E");
}

</style>
