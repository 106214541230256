import { render, staticRenderFns } from "./Registration_verify.vue?vue&type=template&id=417f8254&scoped=true&"
import script from "./Registration_verify.vue?vue&type=script&lang=js&"
export * from "./Registration_verify.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417f8254",
  null
  
)

export default component.exports