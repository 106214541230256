<template>
  <div class="register">

    <div class="vspace_top_page"></div>
    <div class="mode-center">
      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1">
        <div class="">
          <div class="text-hdr-1">
            Wachtwoord herstellen
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3 hidden">
            Hier kan een subtitel komen over de academy
          </div>
        </div>
        <div id="reset_scroll">
          <div class="wrap-regist">
            <div class="rounded-lg bg-white pad-1">
              <div id="reset_hold">
                <div class="form-wrap" id="reset_wrap">
                    <div class="mb-7 text-subhdr-3">
                      {{body_text}}
                    </div>
                    <div class="mb-4">
                      <label class="text-body-1 pb-3" for="password">
                        wachtwoord
                      </label>
                      <input class="input-main text-body-2" id="password" ref="password" v-model="password" type="password">
                    </div>
                    <div class="mb-7">
                      <label class="text-body-1 pb-3" for="password_again">
                        wachtwoord nogmaals
                      </label>
                      <input class="input-main text-body-2" id="password_again" ref="password_again" v-model="password_again" type="password">
                    </div>
                    <div class="p-4 mb-7 rounded-lg bg-orange-50 border border-orange-600 text-orange-600 font-mono font-bold flex flex-row" v-show="error_show">
                      <div class="text-orange-400">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      </div>
                      <div class="ml-4 animate-pulse">
                        {{error_message}}
                      </div>
                    </div>
                    <btn_default text="verstuur" type="w100" @btn-click="formHandle" :state="btn_state" id="passreset_btn" />
                </div>
                <div class="text-subhdr-3 hidden opacity-0" id="reset_end">
                  <span v-html="form_end_message"></span>
                  <br/><br/>
                  <div>
                    <btn_default text="log in" to="/login" type="w100" id="passreset_btn" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>

    <div class="vspace_bottom"></div>

  </div>
</template>
<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import * as cheet from 'cheet.js'
import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin)

import btn_default from '@/components/ui/btn_default.vue'

var lv = {}
lv.thisVue = null
lv.formData = {}
lv.salt = ''

export default {
  name: 'Password_reset',
  components: {
    btn_default
  },
  data() {
    return {
      formDone: false,
      form_end_message: '',
      error_message: '',
      error_show: false,
      password: '',
      password_again: '',
      btn_state: null,
      body_text: null
    }
  },
  mounted(){
    lv.thisVue = this
    lv.salt = lv.thisVue.$route.params.salt
    this.body_text = 'Vul hieronder je nieuwe wachtwoord in'
  },
  methods: {
    formHandle() {
      lv.formData = {
        password: this.password,
        password_again: this.password_again,
        salt: lv.salt
      }
      control_main.console_log(lv.formData)
      formSend(lv.formData)
    }
  },
}

lv.dataTask = 'password_reset'
lv.dataEvent = 'data_password_reset'

function formSend(form_data){
  lv.thisVue.error_show = false
  lv.thisVue.btn_state = 'processing'
  api.call({
    task: lv.dataTask,
    event: lv.dataEvent,
    global: false,
    formData: form_data
  })
}
function dataLoaded(d) {
  lv.thisVue.btn_state = null
  if(d.result == 'nok'){
    formError(d.data.respond)
    return
  }

  if(d.data.respond == 'password_reset_success'){
    lv.msg = 'Je wachtwoord is opnieuw ingesteld.'
    lv.thisVue.form_end_message = lv.msg
    lv.thisVue.formDone = true
    control_main.log_out()
    gsap.delayedCall(0.2, reset_end_show)
  }
}
EventBus.$on(lv.dataEvent, (e) => {
  dataLoaded(e)
})

function formError(e){
  control_main.console_log(e)
  lv.thisVue.error_message = lv.formIssues[e].message
  lv.fld = lv.formIssues[e].field
  lv.thisVue.error_show = true
}

lv.formIssues = { 
  'salt_no_match': {
    field: 'password',
    message: 'Ongeldige actie'
  },
  'salt_not_valid': {
    field: 'password',
    message: 'Ongeldige actie'
  },
  'date_expired': {
    field: 'password',
    message: 'Deze link is niet meer geldig'
  },
  'password_empty': {
    field: 'password',
    message: 'Ongeldige wachtwoorden'
  },
  'password_nomatch': {
    field: 'password',
    message: 'De wachtwoorden komen niet overeen'
  },
  'password_strength': {
    field: 'password',
    message: 'Wachtwoord is niet sterk genoeg.\n\rGebruik tenminste 8 karakters met minimaal 1 hoofdletter, 1 kleine letter en 1 getal.'
  }
}

function reset_end_show(){

  lv.targ = '#reset_hold'
  gsap.set(lv.targ,{height:'auto'})
  lv.targ = '#reset_hold'
  lv.start_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#reset_wrap'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#reset_end'
  gsap.set(lv.targ,{display:'block'})
  lv.targ = '#reset_hold'
  lv.end_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#reset_end'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#reset_wrap'
  gsap.set(lv.targ,{display:'block'})
  
  lv.targ = '#reset_hold'
  gsap.to(lv.targ,{duration:0.1, height: lv.start_height, ease:'power3.inOut'})
  lv.targ = '#reset_wrap'
  gsap.to(lv.targ,{delay: 0.1, duration:0.5, opacity: 0, display: 'none', ease:'power3.inOut'})
  lv.targ = '#reset_hold'
  gsap.to(lv.targ,{delay:0.6, duration:0.5, height: lv.end_height, ease:'power3.inOut'})
  lv.targ = '#reset_end'
  gsap.to(lv.targ,{delay:1.1, duration:0.5, opacity: 1, display: 'block', ease:'power3.inOut'})
  lv.targ = '#reset_hold'
  gsap.to(lv.targ,{delay:1.2, duration:0.1, height: 'auto', ease:'power3.inOut'})

  gsap.delayedCall(1, reset_end_scroll)

}

function reset_end_scroll(){
  lv.targ = '#reset_scroll'
  gsap.to(window, {duration: 0.4, scrollTo: {y: lv.targ, offsetY: 50}, ease:'power3.inOut'})
}

</script>

<style scoped>
</style>