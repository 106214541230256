<template>
  <div class="">
    <div class="vspace_top_page"></div>
    <div class="mode-center">
      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1">
        <div class="rounded-lg bg-white pad-1 " v-if="verify_done">
          <div class="text-body-1">
            <div>
              Hallo {{user_name}},<br/>Je aanmelding is gelukt, je kunt nu inloggen
              <br/><br/>
              <btn_default text="log in" to="/login" />
            </div>
          </div>
        </div>
        <div class="rounded-lg pad-1 bg-orange-200 text-orange-600 " v-if="verify_invalid">
          <div class="text-body-1 ">
            <div class="inline-block align-middle pr-3 text-orange-300">
              <svg class="w-32 h-32" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
            </div>
            <div class="inline-block align-middle text-subhdr-3 pb-3">
              <p class="text-hdr-2">Oeps!</p>
              <p>Deze pagina is niet (meer) geldig.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vspace_bottom"></div>
  </div>
</template>

<script>
import globals from '../../modules/globals'
import { EventBus } from '../../modules/event_bus'
import control_main from '@/modules/control_main'
import api from '../../modules/api'
import * as cheet from 'cheet.js'

import btn_default from '@/components/ui/btn_default.vue'


var lv = {}
lv.thisVue = null

export default {
  name: 'Registration_verify',
  components: {
    btn_default
  },
  data() {
    return {
      verify_done: false,
      verify_invalid: false,
      user_name: null
    };
  },
  mounted(){
    lv.thisVue = this
    lv.salt = lv.thisVue.$route.params.salt

    lv.dataTask = 'registration_verify'
    lv.dataEvent = 'data_registration_verify'
    api.call({
      task: lv.dataTask,
      salt: lv.salt,
      global: false,
      event: lv.dataEvent,
    });
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e);
    });

    function dataLoaded(d) {
      if (d.result != "ok") {
        control_main.console_log("----- data load fail -----")
        lv.thisVue.verify_invalid = true
      }
      if (d.result == "ok") {
        control_main.console_log("----- data load success -----")
        lv.thisVue.user_name = d.data.data.first_name + ' ' + d.data.data.last_name
        lv.thisVue.verify_done = true
      }
    }
  },
  methods: {
  },
}

</script>

<style scoped>

</style>