<template>
  <div class="">

    <div class="vspace_top_page"></div>
    <div class="mode-center">
      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1 gap-y-8 mxmd:gap-y-5">

        <div class="rounded-lg bg-white pad-1">
          <span class="text-hdr-2 text-red-700">
            Account verwijderd
          </span>
          <br /><br />
          Jammer dat je ons hebt verlaten!
        </div>

      </div>
    </div>

    <div class="vspace_bottom"></div>

  </div>
</template>

<script>
import globals from "@/modules/globals"

export default {
  name: "AccountRemoved",
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
      globals.account_is_removed = false
  },
  methods: {
  }
}

</script>


<style scoped>

</style>