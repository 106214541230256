<template>
  <div class="register">

    <div class="vspace_top_page"></div>
    <div class="mode-center">
      <div class="grid grid-cols-2 gap-6 mxmd:grid-cols-1">
        <div class="">
          <div class="text-hdr-1">
            {{loginData.title}}
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3 nativelinks" v-html="loginData.text">
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3 hidden">
            Hier kan een subtitel komen over de academy
          </div>
        </div>
        <div id="form_scroll">
          <div class="wrap-regist">
            <div class="rounded-lg bg-white pad-1">
              <div id="form_hold">
                <div class="form-wrap" id="form_wrap">

                    <div class="p-4 mb-7 rounded-lg bg-green-50 border border-green-600 text-green-600 flex flex-row" v-if="login_after_password_newly_changed">
                      <div class="inline-block mr-6">
                        <svg class="w-12 h-12" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                      </div>
                      <span v-html="login_after_new_password_message"></span>
                    </div>

                    <div class="mb-4">
                      <label class="text-body-1 pb-3" for="email">
                        email adres
                      </label>
                      <input class="input-main text-body-2" id="email" ref="email" v-model="email" type="email">
                    </div>
                    <div class="mb-7">
                      <label class="text-body-1 pb-3" for="password">
                        wachtwoord
                      </label>
                      <input class="input-main text-body-2" id="password" ref="password" v-model="password" type="password">
                    </div>
                    <div class="p-4 mb-7 rounded-lg bg-orange-50 border border-orange-600 text-orange-600 font-mono font-bold flex flex-row" v-show="error_show">
                      <div class="text-orange-400">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      </div>
                      <div class="ml-4 animate-pulse">
                        {{error_message}}
                      </div>
                    </div>
                    <btn_default text="log in" type="w100" @btn-click="formHandle" :state="btn_state" id="login_btn" />
                    <div class="text-mini-1 mt-6 nativelinks">
                      <a href="wachtwoord-vergeten">Wachtwoord vergeten?</a>
                    </div>
                    <div class="text-mini-1 mt-6 nativelinks">
                      <a href="registreren">Ik heb nog geen account</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>

    <div class="vspace_bottom"></div>

  </div>
</template>
<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'
import api from '@/modules/api'
import * as cheet from 'cheet.js'

import btn_default from '@/components/ui/btn_default.vue'


var lv = {}
lv.thisVue = null
lv.formData = {}

export default {
  name: 'Login',
  components: {
    btn_default
  },
  data() {
    return {
      loginData: {},
      logged_in: globals.logged_in,
      error_message: '',
      error_show: false,
      email: '',
      password: '',
      login_after_password_newly_changed: false,
      login_after_new_password_message: 'Je hebt je wachtwoord gewijzigd.<br/>Log daarom opnieuw in.',
      btn_state: null
    }
  },
  mounted(){
    lv.thisVue = this

    if(globals.login_after_new_password){
      this.login_after_password_newly_changed = true
      control_main.console_log('login_after_new_password')
    }

    lv.dataTask_2 = "login_fetch"
    lv.dataEvent_2 = "fetch_login_detail"
    api.call({
      task: lv.dataTask_2,
      global: true,
      event: lv.dataEvent_2,
    })
    EventBus.$on(lv.dataEvent_2, (e) => {
      dataLoaded_2(e)
    })

    function dataLoaded_2(d) {
      console.log('login data fetched', d);
      lv.login_fecth_data = globals.api_data[lv.dataTask_2].item
      lv.thisVue.loginData = {}
      lv.thisVue.loginData.title = lv.login_fecth_data.title
      lv.thisVue.loginData.text = lv.login_fecth_data.text
    }
    /*
    */

  },
  methods: {
    formHandle() {
      this.valid = false
      lv.formData = {
        email: this.email,
        password: this.password,
        remember: 1
      }
      control_main.console_log(lv.formData)
      formSend(lv.formData)
    }
  },
}

lv.dataTask = 'login'
lv.dataEvent = 'data_login'

function formSend(form_data){
  lv.thisVue.btn_state = 'processing'
  lv.thisVue.error_show = false
  lv.thisVue.login_after_password_newly_changed = false
  globals.login_after_new_password = false
  api.call({
    task: lv.dataTask,
    event: lv.dataEvent,
    global: false,
    formData: form_data
  })
}
function dataLoaded(d) {
  lv.thisVue.btn_state = null
  if(d.result == 'nok'){
    formError(d.data.respond)
    return
  }

  if(d.data.respond == 'login_success'){
    //EventBus.$emit('logged_in')
    control_main.login_check()
  }
}
EventBus.$on(lv.dataEvent, (e) => {
  dataLoaded(e);
})
EventBus.$on('login_check_success', (e) => {
  lv.thisVue.$router.push({ name: 'Courses' }).catch(()=>{})
})

function formError(e){
  control_main.console_log(e)
  lv.thisVue.error_message = lv.formIssues[e].message
  lv.thisVue.error_show = true
}

lv.formIssues = {
  'user_not_found': {
    field: 'email',
    message: 'E-mail of wachtwoord onjuist'
  },
  'password_incorrect': {
    field: 'email',
    message: 'E-mail of wachtwoord onjuist'
  }
}

</script>

<style scoped>
</style>