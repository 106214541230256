<template>
  <div class="logout">
  </div>
</template>
<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import api from '@/modules/api'
import control_main from '@/modules/control_main'

var lv = {}
lv.thisVue = null

export default {
  name: 'Logout',

  data() {
    return {
    }
  },
  mounted(){
    lv.thisVue = this
    control_main.log_out()
  }
}

EventBus.$on('logged_out', (e) => {
  if(!globals.account_is_removed){
    lv.thisVue.$router.push({ name: 'Home' }).catch(()=>{})
  }
})


</script>


<style scoped>
.logout{
  height: calc(100vh - 117px - 191px);
}
</style>