<template>
  <div class="register">

    <div class="vspace_top_page"></div>
    <div class="mode-center">
      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1 gap-y-8 mxmd:gap-y-5">
        <div class="">
          <div class="text-hdr-1">
            Profiel aanpassen
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3 hidden">
            Hier kan een subtitel komen over de academy
          </div>
        </div>

        <div id="profile_1_scroll">
          <div class="wrap-regist">
            <div class="rounded-lg pad-1 bg-blue-ice">
              <div id="profile_1_hold">
                <div class="form-wrap" id="profile_1_wrap">
                  <div class="text-center relative">
                    <div class="inline-block relative">
                      <avatar :size="2" :src="avatar_src"></avatar>
                      <div class="pos-g cursor-pointer" @click="uploadTrigger()"></div>
                    </div>
                  </div>
                   <input type="file" id="file" ref="file" class="hidden" @change="fileUpload()" />
                    <div class="mb-4">
                      <label class="text-body-1 pb-3" for="first_name">
                        voornaam
                      </label>
                      <input class="input-main text-body-2" id="first_name" ref="first_name" v-model="first_name" type="text">
                    </div>
                    <div class="mb-4">
                      <label class="text-body-1 pb-3" for="last_name">
                        achternaam
                      </label>
                      <input class="input-main text-body-2" id="last_name" ref="last_name" v-model="last_name" type="text">
                    </div>
                    <div class="mb-9">
                      <label class="text-body-1 pb-3" for="motivation">
                        je motivatie
                      </label>
                      <div class="relative">
                        <textarea class="resize-none input-main" rows="4" id="motivation" v-model="motivation_text" maxlength="200"></textarea>
                        <div class="absolute pointer-events-none text-gray-300 text-counter">
                          {{motivation_text.length}}/{{motivation_length}}
                        </div>
                      </div>
                    </div>
                    <div class="p-4 mb-7 rounded-lg bg-orange-50 border border-orange-600 text-orange-600 font-mono font-bold flex flex-row" v-show="error_show">
                      <div class="text-orange-400">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      </div>
                      <div class="ml-4 animate-pulse">
                        {{error_message}}
                      </div>
                    </div>
                    <div class="p-4 mb-7 rounded-lg bg-green-50 border border-green-600 text-green-600 font-mono font-bold flex flex-row" v-show="success_show">
                      <div class="text-green-400">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>                        
                      </div>
                      <div class="ml-4">
                        {{success_message}}
                      </div>
                    </div>
                    <btn_default text="opslaan" type="w100" @btn-click="formHandle" :state="btn_state" id="profile_2_btn" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="filler"></div>

        <div id="profile_3_scroll">
          <div class="wrap-regist h-full">
            <div class="rounded-lg bg-blue-ice pad-1 h-full dark:bg-blue-ice">
              <div id="profile_3_hold">
                <div class="pb-4">
                  <a href="#wachtwoord-wijzigen" @click.prevent="modal_open('wachtwoord-wijzigen','small','password_change')" class="text-red-600 px-2 py-2 rounded-md hover:bg-red-500 hover:text-white" >Wachtwoord wijzigen?</a>
                </div>
                <div class="pb-1">
                  <a href="#account-verwijderen" @click.prevent="modal_open('account-verwijderen','small','account_remove')" class="text-red-600 px-2 py-2 rounded-md hover:bg-red-500 hover:text-white" >Account verwijderen?</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="vspace_bottom"></div>

    <modal_default />

  </div>
</template>


<script>
import globals from "@/modules/globals"
import { EventBus } from "@/modules/event_bus"
import control_main from '@/modules/control_main'
import api from "@/modules/api"
import * as cheet from "cheet.js"

import avatar from "@/components/account/avatar.vue"

import btn_default from '@/components/ui/btn_default.vue'
import modal_default from '@/components/ui/modal_default.vue'

var lv = {}
lv.thisVue = null
lv.formData = {}

export default {
  name: "Profile",
  components: {
    avatar,
    btn_default,
    modal_default
  },
  data() {
    return {
      file: "",
      avatar_src: '',
      user_name: '',
      success_message: "",
      success_show: false,
      error_message: "",
      error_show: false,
      first_name: "",
      last_name: "",
      motivation_text: '',
      motivation_length: 200,
      btn_state: null
    }
  },
  mounted() {
    lv.thisVue = this
    profile_set_vars()
  },
  methods: {
    formHandle() {
      lv.formData = {
        first_name: this.first_name,
        last_name: this.last_name,
        motivation: this.motivation_text
      }
      control_main.console_log(lv.formData)
      formSend(lv.formData)
    },
    uploadTrigger() {
      this.$refs.file.click()
    },
    fileSelected() {
      control_main.console_log(this.$refs.file.files[0])
    },
    fileUpload() {
      this.file = this.$refs.file.files[0]
      api.avatarUpload(this.file)
    },
    modal_open(who, size, modal_module){
      lv.modal_data = {
        id: who,
        size: size,
        module: modal_module
      }
      EventBus.$emit('modal_open', lv.modal_data)
    }
  }
}

EventBus.$on('logged_out', (e) => {
  if(!globals.account_is_removed){
    lv.thisVue.$router.push({ name: 'Login' }).catch(()=>{})
  }
})

function profile_set_vars(){
    if(globals.user_data.first_name){
      lv.thisVue.first_name = globals.user_data.first_name
    }
    if(globals.user_data.last_name){
      lv.thisVue.last_name = globals.user_data.last_name
    }
    lv.thisVue.user_name = lv.thisVue.first_name + ' ' + lv.thisVue.last_name
    if(globals.user_data.avatar){
      lv.thisVue.avatar_src = globals.user_data.avatar
    }
    if(globals.user_data.motivation){
      lv.thisVue.motivation_text = globals.user_data.motivation
    }
}

EventBus.$on('avatar_uploaded', (e) => {
  if(e.result == 'ok'){
    lv.thisVue.avatar_src = e.avatar
  }
})
EventBus.$on('login_check_success', (e) => {
  profile_set_vars()
})

lv.dataTask = "profile_set"
lv.dataEvent = "data_profile_set"

function formSend(form_data) {
  lv.thisVue.error_show = false
  lv.thisVue.success_show = false;
  form_data.user_id = globals.user_data.id

  api.call({
    task: lv.dataTask,
    event: lv.dataEvent,
    global: false,
    formData: form_data
  })
}
function dataLoaded(d) {
  if (d.result == "nok") {
    formError(d.data.respond);
    return
  }
  if (d.result == "ok") {
    lv.msg = "Profiel bijgewerkt."
    lv.thisVue.success_message = lv.msg;
    lv.thisVue.success_show = true;
  }
}
EventBus.$on(lv.dataEvent, (e) => {
  dataLoaded(e);
})

function formError(e) {
  control_main.console_log(e);
  lv.thisVue.error_message = lv.formIssues[e].message
  lv.fld = lv.formIssues[e].field
  lv.thisVue.error_show = true
}

lv.formIssues = {
  first_name_empty: {
    field: "first_name",
    message: "Voornaam is vereist",
  },
  last_name_empty: {
    field: "last_name",
    message: "Achternaam is vereist",
  }
}


/* ---------------------------------------------------------------------------- */

</script>


<style scoped>

.text-counter{
  bottom:-22px;
  right:6px;
}
</style>