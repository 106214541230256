<template>
  <div class="register">

    <div class="vspace_top_page"></div>
    <div class="mode-center">
      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1">
        <div class="">
          <div class="text-hdr-1">
            {{registerData.title}}
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3 nativelinks" v-html="registerData.text">
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3 hidden">
            Hier kan een subtitel komen over de academy
          </div>
        </div>
        <div id="form_scroll">
          <div class="wrap-regist">
            <div class="rounded-lg bg-white pad-1">
              <div id="form_hold">
                <div class="form-wrap" id="form_wrap">
                    <div class="mb-4">
                      <label class="text-body-1 pb-3" for="first_name">
                        voornaam
                      </label>
                      <input class="input-main text-body-2" id="first_name" ref="first_name" v-model="first_name" type="text" v-bind:class="first_name__error">
                    </div>
                    <div class="mb-4">
                      <label class="text-body-1 pb-3" for="last_name">
                        achternaam
                      </label>
                      <input class="input-main text-body-2" id="last_name" ref="last_name" v-model="last_name" type="text" v-bind:class="last_name__error">
                    </div>
                    <div class="mb-4">
                      <label class="text-body-1 pb-3" for="email">
                        email adres
                      </label>
                      <input class="input-main text-body-2" id="email" ref="email" v-model="email" type="email" v-bind:class="email__error">
                    </div>
                    <div class="mb-4">
                      <label class="text-body-1 pb-3" for="password">
                        wachtwoord
                      </label>
                      <input class="input-main text-body-2" id="password" ref="password" v-model="password" type="password" v-bind:class="password__error">
                    </div>
                    <div class="mb-7">
                      <label class="text-body-1 pb-3" for="password_again">
                        wachtwoord nogmaals
                      </label>
                      <input class="input-main text-body-2" id="password_again" ref="password_again" v-model="password_again" type="password" v-bind:class="password_again__error">
                    </div>
                    <div class="p-4 mb-7 rounded-lg bg-orange-50 border border-orange-600 text-orange-600 font-mono font-bold flex flex-row" v-show="error_show">
                      <div class="text-orange-400">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      </div>
                      <div class="ml-4 animate-pulse">
                        {{error_message}}
                      </div>
                    </div>
                    <btn_default text="registreren" type="w100" @btn-click="formHandle" :state="btn_state" id="form_btn" />
                    <div class="text-mini-1 mt-6 nativelinks">
                      Als je je aanmeldt voor Diversion Academy ga je akkoord met onze <a href="#gebruiksvoorwaarden" @click.prevent="modal_open('gebruiksvoorwaarden')" >Gebruiksvoorwaarden</a> & <a href="#privacyverklaring" @click.prevent="modal_open('privacyverklaring')">Privacyverklaring</a>
                    </div>
                </div>
                <div class="text-subhdr-3" id="form_end">
                  <div class="icon_wrap">
                    <div class="inline-block icon_w">
                      <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" width="283.4599914550781" height="283.4599914550781"><g class="a"><circle class="b svg-elem-1" cx="141.73" cy="141.73" r="115.63"></circle><rect class="c svg-elem-2" x="89.41" y="93.02" width="167.95" height="107.99"></rect><polygon class="c svg-elem-3" points="89.41 201.01 173.38 128.73 257.36 201.01 89.41 201.01"></polygon><polyline class="c svg-elem-4" points="89.41 104.79 173.38 155.81 257.36 104.79"></polyline><path class="c svg-elem-5" d="M150.37,88a6.54,6.54,0,0,1,0,10c-10.26,8.64-33.7,25.4-61,25.4s-50.7-16.76-61-25.4a6.54,6.54,0,0,1,0-10c10.26-8.64,33.69-25.4,61-25.4S140.11,79.37,150.37,88Z"></path><circle class="d svg-elem-6" cx="89.41" cy="93.02" r="29.52"></circle><circle class="c svg-elem-7" cx="89.41" cy="93.02" r="8.93"></circle></g></svg>
                    </div>
                  </div>
                  <div v-html="form_end_message"></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="vspace_bottom"></div>

    <modal_default />

  </div>
</template>

<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import * as cheet from 'cheet.js'
import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);


import btn_default from '@/components/ui/btn_default.vue'
import modal_default from '@/components/ui/modal_default.vue'

var lv = {}
lv.thisVue = null
lv.formData = {}

export default {
  name: 'Register',
  components: {
    btn_default,
    modal_default
  },
  data() {
    return {
      registerData: {},
      error_show: false,
      error_message: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_again: '',
      input_error_class: 'border-orange-600 ring ring-2 ring-orange-50',
      first_name__error: '',
      last_name__error: '',
      email__error: '',
      password__error: '',
      password_again__error: '',
      form_end_message: '',
      btn_state: null
    }
  },
  mounted() {
    lv.thisVue = this

    lv.dataTask = "register_fetch"
    lv.dataEvent = "data_register_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e)
    })

    function dataLoaded(d) {
      console.log('register data fetched', d);
      lv.register_data = globals.api_data[lv.dataTask].item
      lv.thisVue.registerData = {}
      lv.thisVue.registerData.title = lv.register_data.title
      lv.thisVue.registerData.text = lv.register_data.text
    }

  },
  methods: {
    modal_open(who){
      lv.modal_data = {
        id: who
      }
      EventBus.$emit('modal_open', lv.modal_data)
    },
    formHandle() {
      resetErrors()
      lv.formData = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        password_again: this.password_again,
      }
      control_main.console_log(lv.formData)
      formSend(lv.formData)
    }
  }
}

function resetErrors(){
    lv.thisVue['first_name__error'] = ''
    lv.thisVue['last_name__error'] = ''
    lv.thisVue['email__error'] = ''
    lv.thisVue['password__error'] = ''
    lv.thisVue['password_again__error'] = ''
}

lv.dataTask_reg = "registration"
lv.dataEvent_reg = "data_register"
lv.apiBusy = false

function formSend(form_data){
  if(lv.apiBusy){
    return
  }
  lv.thisVue.btn_state = 'processing'
  lv.apiBusy = true
  lv.thisVue.error_show = false
  api.call({
    task: lv.dataTask_reg,
    event: lv.dataEvent_reg,
    global: false,
    formData: form_data
  })
}
function dataLoaded(d) {
  lv.thisVue.btn_state = null
  lv.apiBusy = false
  if(d.result == 'nok'){
    formError(d.data.respond)
    return
  }
  if (d.result == 'ok') {    
    lv.msg = '<b>Hartelijk dank voor je aanmelding.</b> <br/>Er is een e-mail gestuurd naar het onderstaande e-mailadres.<br/><br/>'
    lv.msg += 'Klik op de verificatie link in de e-mail om je aanmelding te bevestigen.<br/><br/> <b>' + lv.formData.email + '</b>'
    lv.thisVue.form_end_message = lv.msg
    gsap.delayedCall(0.2, form_end_show)
    return
  }
  lv.thisVue.error_message = 'Er is helaas iets misgegaan...'
  lv.thisVue.error_show = true
}
EventBus.$on(lv.dataEvent_reg, (e) => {
  dataLoaded(e);
})

function formError(e){
  control_main.console_log(e)
  lv.thisVue.error_message = lv.formIssues[e].message
  lv.thisVue.error_show = true
  lv.fld = lv.formIssues[e].field
  lv.thisVue[lv.fld + '__error'] = lv.thisVue.input_error_class
}

lv.formIssues = {
  'first_name_empty': {
    field: 'first_name',
    message: 'Voornaam is vereist'
  },
  'last_name_empty': {
    field: 'last_name',
    message: 'Achternaam is vereist'
  },
  'email_invalid': {
    field: 'email',
    message: 'E-mail is ongeldig'
  },
  'email_exists': {
    field: 'email',
    message: 'Dit e-mailadres bestaat al!'
  },
  'password_empty': {
    field: 'password',
    message: 'Wachtwoord is vereist'
  },
  'password_strength': {
    field: 'password',
    message: 'Wachtwoord is niet sterk genoeg.\n\rGebruik tenminste 8 karakters met minimaal 1 hoofdletter, 1 kleine letter en 1 getal.'
  },
  'password_nomatch': {
    field: 'password_again',
    message: 'Wachtwoorden komen niet overeen'
  },
  'agree': {
    field: 'agree',
    message: 'Akkoord met de voorwaarden is vereist'
  }
}

function form_end_show(){

  lv.targ = '#form_hold'
  gsap.set(lv.targ,{height:'auto'})
  lv.targ = '#form_hold'
  lv.start_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#form_wrap'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#form_end'
  gsap.set(lv.targ,{display:'block'})
  lv.targ = '#form_hold'
  lv.end_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#form_end'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#form_wrap'
  gsap.set(lv.targ,{display:'block'})
  
  lv.targ = '#form_hold'
  gsap.to(lv.targ,{duration:0.1, height: lv.start_height, ease:'power3.inOut'})
  lv.targ = '#form_wrap'
  gsap.to(lv.targ,{delay: 0.1, duration:0.5, opacity: 0, display: 'none', ease:'power3.inOut'})
  lv.targ = '#form_hold'
  gsap.to(lv.targ,{delay:0.6, duration:0.5, height: lv.end_height, ease:'power3.inOut'})
  lv.targ = '#form_end'
  gsap.to(lv.targ,{delay:1.1, duration:0.5, opacity: 1, display: 'block', ease:'power3.inOut'})
  lv.targ = '#form_hold'
  gsap.to(lv.targ,{delay:1.2, duration:0.1, height: 'auto', ease:'power3.inOut'})

  gsap.delayedCall(1, form_end_scroll)

}

function form_end_scroll(){
  lv.rect = document.getElementById('form_scroll').getBoundingClientRect()
  lv.window_height = window.innerHeight
  lv.rest = window.innerHeight - (window.innerHeight - lv.rect.top)
  if(lv.rest < 0){
    lv.targ = '#form_scroll'
    gsap.to(window, {duration: 0.4, scrollTo: {y: lv.targ, offsetY: 50}, ease:'power3.inOut'})
  }
}


</script>

<style scoped>

#form_end{
  display: none;
  opacity: 0;
}
.icon_wrap{
  text-align: center;
}
.icon_w{
  width:150px;
  height: 150px;
}

.a{isolation:isolate;}.b{fill:#dfeaf8;mix-blend-mode:multiply;}.b,.c{stroke:#1d1d1b;stroke-linejoin:bevel;stroke-width:2px;}.c{fill:#fff;}.d{fill:#1d1d1b;}




@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 728.5247170691755px;
    stroke-dasharray: 728.5247170691755px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(223, 234, 248);
  }
}

.svg-elem-1 {
  animation: animate-svg-stroke-1 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.3s both,
               animate-svg-fill-1 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s both;
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 553.8799438476562px;
    stroke-dasharray: 553.8799438476562px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-2 {
  animation: animate-svg-stroke-2 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.42s both,
               animate-svg-fill-2 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.1s both;
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 391.5459899902344px;
    stroke-dasharray: 391.5459899902344px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-3 {
  animation: animate-svg-stroke-3 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.54s both,
               animate-svg-fill-3 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.2s both;
}

@keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 198.51808166503906px;
    stroke-dasharray: 198.51808166503906px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-4 {
  animation: animate-svg-stroke-4 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.6599999999999999s both,
               animate-svg-fill-4 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.3s both;
}

@keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 294.3470764160156px;
    stroke-dasharray: 294.3470764160156px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-5 {
  animation: animate-svg-stroke-5 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.78s both,
               animate-svg-fill-5 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.4s both;
}

@keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 187.4796302679414px;
    stroke-dasharray: 187.4796302679414px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(29, 29, 27);
  }
}

.svg-elem-6 {
  animation: animate-svg-stroke-6 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.8999999999999999s both,
               animate-svg-fill-6 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.5s both;
}

@keyframes animate-svg-stroke-7 {
  0% {
    stroke-dashoffset: 58.108844793113704px;
    stroke-dasharray: 58.108844793113704px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-7 {
  animation: animate-svg-stroke-7 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.02s both,
               animate-svg-fill-7 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.6s both;
}


</style>