<template>
  <div class="register">

    <div class="vspace_top_page"></div>
    <div class="mode-center">
      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1">
        <div class="">
          <div class="text-hdr-1">
            Wachtwoord vergeten
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3 hidden">
            Hier kan een subtitel komen over de academy
          </div>
        </div>
        <div id="forgot_scroll">
          <div class="wrap-regist">
            <div class="rounded-lg bg-white pad-1">
              <div id="forgot_hold">
                <div class="form-wrap" id="forgot_wrap">
                    <div class="mb-7 text-subhdr-3">
                      {{body_text}}
                    </div>
                    <div class="mb-7">
                      <label class="text-body-1 pb-3" for="email">
                        email adres
                      </label>
                      <input class="input-main text-body-2" id="email" ref="email" v-model="email" type="email">
                    </div>
                    <div class="p-4 mb-7 rounded-lg bg-orange-50 border border-orange-600 text-orange-600 font-mono font-bold flex flex-row" v-show="error_show">
                      <div class="text-orange-400">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      </div>
                      <div class="ml-4 animate-pulse">
                        {{error_message}}
                      </div>
                    </div>
                    <btn_default text="verstuur" type="w100" @btn-click="formHandle" :state="btn_state" id="passforgot_btn" />
                </div>
                <div class="text-subhdr-3 hidden opacity-0" id="forgot_end">
                  <div class="icon_wrap">
                    <div class="inline-block icon_w">
                      <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" width="283.4599914550781" height="283.4599914550781"><g class="a"><circle class="b svg-elem-1" cx="141.73" cy="141.73" r="115.63"></circle><rect class="c svg-elem-2" x="89.41" y="93.02" width="167.95" height="107.99"></rect><polygon class="c svg-elem-3" points="89.41 201.01 173.38 128.73 257.36 201.01 89.41 201.01"></polygon><polyline class="c svg-elem-4" points="89.41 104.79 173.38 155.81 257.36 104.79"></polyline><path class="c svg-elem-5" d="M150.37,88a6.54,6.54,0,0,1,0,10c-10.26,8.64-33.7,25.4-61,25.4s-50.7-16.76-61-25.4a6.54,6.54,0,0,1,0-10c10.26-8.64,33.69-25.4,61-25.4S140.11,79.37,150.37,88Z"></path><circle class="d svg-elem-6" cx="89.41" cy="93.02" r="29.52"></circle><circle class="c svg-elem-7" cx="89.41" cy="93.02" r="8.93"></circle></g></svg>
                    </div>
                  </div>
                  <div v-html="form_end_message"></div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>

    <div class="vspace_bottom"></div>

  </div>
</template>
<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'
import api from '@/modules/api'

import * as cheet from 'cheet.js'
import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin)

import btn_default from '@/components/ui/btn_default.vue'

var lv = {}
lv.thisVue = null
lv.formData = {}

export default {
  name: 'Password_forgot',
  components: {
    btn_default
  },
  data() {
    return {
      formDone: false,
      form_end_message: '',
      error_message: '',
      error_show: false,
      forgot_end_message: '',
      email: '',
      btn_state: null,
      body_text: null
    }
  },
  mounted(){
    lv.thisVue = this

    this.body_text = 'Vul hieronder het e-mailadres in waarmee je je hebt aangemeld, dan sturen we je een link waarmee je je wachtwoord opnieuw kunt instellen.'
  },
  methods: {
    formHandle() {
      lv.formData = {
        email: this.email
      }
      formSend(lv.formData)
    }
  },
}

lv.dataTask = 'password_reset_request'
lv.dataEvent = 'data_password_reset_request'

function formSend(form_data){
  lv.thisVue.error_show = false
  lv.thisVue.btn_state = 'processing'
  api.call({
    task: lv.dataTask,
    event: lv.dataEvent,
    global: false,
    formData: form_data
  })
}
function dataLoaded(d) {
  lv.thisVue.btn_state = null
  if(d.result == 'nok'){
    formError(d.data.respond)
    return
  }

  lv.msg = 'Je vindt een link in je e-mail waarmee je je wachtwoord opnieuw kunt instellen.'
  lv.thisVue.form_end_message = lv.msg
  lv.thisVue.formDone = true
  gsap.delayedCall(0.2, forgot_end_show)
}
EventBus.$on(lv.dataEvent, (e) => {
  dataLoaded(e);
})

function formError(e){
  control_main.console_log(e)
  lv.thisVue.error_message = lv.formIssues[e].message
  lv.fld = lv.formIssues[e].field
  lv.thisVue.error_show = true
}

lv.formIssues = {
  'email_not_found': {
    field: 'email',
    message: 'Dit e-mailadres is niet bekend'
  }
}

function forgot_end_show(){

  lv.targ = '#forgot_hold'
  gsap.set(lv.targ,{height:'auto'})
  lv.targ = '#forgot_hold'
  lv.start_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#forgot_wrap'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#forgot_end'
  gsap.set(lv.targ,{display:'block'})
  lv.targ = '#forgot_hold'
  lv.end_height = gsap.getProperty(lv.targ, 'height')
  lv.targ = '#forgot_end'
  gsap.set(lv.targ,{display:'none'})
  lv.targ = '#forgot_wrap'
  gsap.set(lv.targ,{display:'block'})
  
  lv.targ = '#forgot_hold'
  gsap.to(lv.targ,{duration:0.1, height: lv.start_height, ease:'power3.inOut'})
  lv.targ = '#forgot_wrap'
  gsap.to(lv.targ,{delay: 0.1, duration:0.5, opacity: 0, display: 'none', ease:'power3.inOut'})
  lv.targ = '#forgot_hold'
  gsap.to(lv.targ,{delay:0.6, duration:0.5, height: lv.end_height, ease:'power3.inOut'})
  lv.targ = '#forgot_end'
  gsap.to(lv.targ,{delay:1.1, duration:0.5, opacity: 1, display: 'block', ease:'power3.inOut'})
  lv.targ = '#forgot_hold'
  gsap.to(lv.targ,{delay:1.2, duration:0.1, height: 'auto', ease:'power3.inOut'})

  gsap.delayedCall(1, forgot_end_scroll)

}

function forgot_end_scroll(){
  lv.targ = '#forgot_scroll'
  gsap.to(window, {duration: 0.4, scrollTo: {y: lv.targ, offsetY: 50}, ease:'power3.inOut'})
}

</script>

<style scoped>

.icon_wrap{
  text-align: center;
}
.icon_w{
  width:150px;
  height: 150px;
}

.a{isolation:isolate;}.b{fill:#dfeaf8;mix-blend-mode:multiply;}.b,.c{stroke:#1d1d1b;stroke-linejoin:bevel;stroke-width:2px;}.c{fill:#fff;}.d{fill:#1d1d1b;}




@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 728.5247170691755px;
    stroke-dasharray: 728.5247170691755px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(223, 234, 248);
  }
}

.svg-elem-1 {
  animation: animate-svg-stroke-1 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.3s both,
               animate-svg-fill-1 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s both;
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 553.8799438476562px;
    stroke-dasharray: 553.8799438476562px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-2 {
  animation: animate-svg-stroke-2 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.42s both,
               animate-svg-fill-2 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.1s both;
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 391.5459899902344px;
    stroke-dasharray: 391.5459899902344px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-3 {
  animation: animate-svg-stroke-3 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.54s both,
               animate-svg-fill-3 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.2s both;
}

@keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 198.51808166503906px;
    stroke-dasharray: 198.51808166503906px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-4 {
  animation: animate-svg-stroke-4 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.6599999999999999s both,
               animate-svg-fill-4 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.3s both;
}

@keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 294.3470764160156px;
    stroke-dasharray: 294.3470764160156px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-5 {
  animation: animate-svg-stroke-5 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.78s both,
               animate-svg-fill-5 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.4s both;
}

@keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 187.4796302679414px;
    stroke-dasharray: 187.4796302679414px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(29, 29, 27);
  }
}

.svg-elem-6 {
  animation: animate-svg-stroke-6 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.8999999999999999s both,
               animate-svg-fill-6 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.5s both;
}

@keyframes animate-svg-stroke-7 {
  0% {
    stroke-dashoffset: 58.108844793113704px;
    stroke-dasharray: 58.108844793113704px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-7 {
  animation: animate-svg-stroke-7 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.02s both,
               animate-svg-fill-7 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.6s both;
}
</style>